<template>
  <div class='back'>
    <el-button type="primary" icon="el-icon-arrow-left" plain @click="back">返回</el-button>
  </div>
</template>

<script>

export default {
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
    back () {
      if (window.history.length < 3) {
        this.$router.replace(this.$store.state.isDetail)
      } else {
        this.$router.go(-1)
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.back {
  padding: 10px 0 0 10px;
  box-sizing: border-box;

  .el-button {
    margin-bottom: 10px;
  }
}
</style>
